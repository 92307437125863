<template>
  <div id="crowded">
    <div class="crowded-box">
      <img src="@/assets/img/Special/stock.png" />
      <h1>暂时缺货中</h1>
      <div>
        活动太火爆目前缺货中，预计3个工作日 内补货完成，请您后续再兑换
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
#crowded {
  background: #f9fcff;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  .crowded-box {
    flex: auto;
    & > img {
      display: block;
      margin: auto;
      width: 70.59vw;
    }
    & > h1 {
      font-size: 4.68vw;
      padding: 6.63vw 0 5.2vw;
      color: #666666;
      text-align: center;
    }
    & > div {
      width: 54vw;
      margin: auto;
      color: #999999;
      font-size: 3.12vw;
      line-height: 1.5;
    }
  }
}
</style>
